import InfoOutlined from '@mui/icons-material/InfoOutlined'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {
  Box,
  Collapse,
  List as _List,
  ListItem,
  Menu,
  MenuItem as _MenuItem,
  popoverClasses,
  styled,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'classnames'
import { SearchInput } from 'packs/dashboard/components/SearchInput/SearchInput'
import CustomIcon, { Icons } from 'packs/main/components/CustomIcon/CustomIcon'
import { useEnvironmentQuestion } from 'packs/main/Environments/ActiveEnvironmentContext/useEnvironmentQuestion'
import trackEvent from 'packs/main/track_event'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { FixedSizeList, ListChildComponentProps } from 'react-window'
import { Language, LanguageConfiguration } from 'utils/languages'

import { useSnippet } from '../../../../graphql/hooks/snippets/useSnippet'
import { Snippet } from '../../../../graphql/types'
import { usePadConfigValues } from '../../../dashboard/components/PadContext/PadContext'
import { EnvironmentTypes } from '../../Environments/EnvironmentsContext/EnvironmentsContext'
import { EnvironmentSummary } from '../../Environments/EnvironmentsContext/types'
import LanguageIcon from '../../Monaco/FilePane/LanguageIcon'
import { ScrollView } from '../../ScrollView/ScrollView'
import { selectPadSettings } from '../../selectors'
import {
  EnvironmentSelectorMenuTabs,
  useEnvironmentSelectorMenuContext,
} from '../EnvironmentSelectorMenuContext'
import { useLanguageInfo } from '../LanguageInfoProvider'
import { useChangeEnvironmentLanguage, useSetEnvironmentCustomDBLanguage } from './actions'
import { CreateQuestionDialog } from './CreateQuestionDialog'
import { QuestionTitle, QuestionTitleSubtext } from './EnvSettingsMenu.style'
import { ResetEnvironmentDialog } from './ResetEnvironmentDialog'
import { SnippetInfoDialog } from './SnippetInfoDialog'

const StyledLanguageIcon = styled(LanguageIcon)(() => ({
  maxHeight: 24,
  width: 24,
}))

const useStyles = makeStyles((theme) => ({
  languageMenuIndicator: {
    height: '20px',
    width: '20px',
    transition: '0.25s all',
    '&.open': {
      transform: 'rotate(0.25turn)',
    },
  },
  menuRoot: {
    marginLeft: '2px',
  },
  menuList: {
    maxWidth: 198,
  },
}))

const MenuItem = styled(_MenuItem)({
  fontSize: '0.75rem',
})
const List = styled(_List)(({ theme }) => ({
  fontSize: '0.75rem',
  backgroundColor: theme.palette.editor.sidebar.background,
}))
export const EnvSettingsMenu: React.FC<{
  requestEnvironmentDelete: (env: EnvironmentSummary) => void
  anchorEl: HTMLButtonElement | null
  setAnchorEl: (el: HTMLButtonElement | null) => void
  environment: EnvironmentSummary
  isMouseOnTab: boolean
}> = ({ requestEnvironmentDelete, anchorEl, setAnchorEl, environment, isMouseOnTab }) => {
  const langsMap = window.CoderPad.LANGUAGES
  const [langSelectionOpen, setLangSelectionOpen] = useState(false)
  const [packageSelectionOpen, setPackageSelectionOpen] = useState(false)
  const [envToReset, setEnvToReset] = useState<EnvironmentSummary | null>(null)
  const [envToCreateQuestionFrom, setEnvToCreateQuestionFrom] = useState<EnvironmentSummary | null>(
    null
  )
  const [snippetInfoDetail, setSnippetInfoDetail] = useState<Snippet | null>(null)
  const { openMenuAndSetTab } = useEnvironmentSelectorMenuContext()
  const [isMouseOnMenu, setIsMouseOnMenu] = useState(false)

  const question = useEnvironmentQuestion(environment?.questionId)
  const { snippet } = useSnippet(environment?.snippet)
  const styles = useStyles(langSelectionOpen)
  const changeLanguage = useChangeEnvironmentLanguage(environment)
  const setCustomDatabaseLanguage = useSetEnvironmentCustomDBLanguage(environment)
  const {
    isOwner,
    questionsEnabled,
    takeHome,
    sandboxEnvironmentPreviewSlug,
    isPlayback,
    drawingBoardId,
  } = usePadConfigValues(
    'isOwner',
    'questionsEnabled',
    'takeHome',
    'sandboxEnvironmentPreviewSlug',
    'isPlayback',
    'drawingBoardId'
  )
  const { customDatabaseLanguage, language: currentLanguage } = useSelector(selectPadSettings)

  const { setLanguage } = useLanguageInfo()

  // Environment change should close this menu.
  useEffect(() => {
    setAnchorEl(null)
  }, [environment, setAnchorEl])

  // Close submenus when the settings menu is closed.
  useEffect(() => {
    if (!anchorEl || !(isMouseOnMenu || isMouseOnTab)) {
      setLangSelectionOpen(false)
      setPackageSelectionOpen(false)
    }
  }, [anchorEl, isMouseOnMenu, isMouseOnTab])

  // there are two pathways to "changing the language of a pad":
  // 1) for a classic-language Question environment, the user can select from a list of languages, and when they change
  //    the language, it mutates the existing question environment.  this allows the objects that are associated with the
  //    environment (custom files, custom databases, candidate instructions) to persist without having to be added to a new
  //    environment
  // 2) for all other environments, the user can "switch language", which will open the environment selector and allow them
  //    to pick a new language/project template/question, which will spawn a new environment
  const allowLangChange =
    environment?.kind === EnvironmentTypes.Question &&
    environment?.projectTemplateSlug == null &&
    environment?.spreadsheet == null

  const isCurrentLanguageDBLanguage = useMemo(
    () => ([Language.MYSQL, Language.POSTGRESQL] as string[]).includes(currentLanguage),
    [currentLanguage]
  )

  const availableLangs = useMemo(() => {
    if (!allowLangChange) return []

    if (!question?.testCasesEnabled && !customDatabaseLanguage && !isCurrentLanguageDBLanguage)
      return Object.values(langsMap).filter((language) => language.category !== 'spreadsheets')

    return Object.values(langsMap).filter((language) => {
      const testCasesAllowed = question?.testCasesEnabled ? language.test_case_grading : true
      const otherDbLanguages = [Language.MYSQL, Language.POSTGRESQL].filter(
        (dbLanguage) => dbLanguage !== customDatabaseLanguage && dbLanguage !== currentLanguage
      ) as string[]
      const matchingDbLanguage = customDatabaseLanguage
        ? !otherDbLanguages.includes(language.name)
        : true

      return testCasesAllowed && matchingDbLanguage
    })
  }, [
    allowLangChange,
    question?.testCasesEnabled,
    customDatabaseLanguage,
    isCurrentLanguageDBLanguage,
    langsMap,
    currentLanguage,
  ])

  const [filteredLangs, setFilteredLangs] = useState(availableLangs)
  // Reset the filtered langs list when the available languages change. This handles the case where the available
  // languages change, eg. when a question with test cases is loaded, thereby reducing the available languages
  // to only those that support test cases.
  useEffect(() => {
    setFilteredLangs(availableLangs)
  }, [availableLangs])

  const availablePackages = useMemo(() => {
    if (!environment?.language) return []

    const langMeta: LanguageConfiguration = window.CoderPad.LANGUAGES[environment.language]
    const allPackages = langMeta?.packages ? Object.values(langMeta.packages) : []

    if (!customDatabaseLanguage) return allPackages

    return allPackages.filter(({ database }) => database === customDatabaseLanguage)
  }, [customDatabaseLanguage, environment.language])

  const hasLangInfo =
    environment?.kind === EnvironmentTypes.Language ||
    environment?.projectTemplateSlug?.startsWith('jupyter') ||
    (environment?.kind === EnvironmentTypes.Question &&
      environment?.projectTemplateSlug == null &&
      !environment?.slug.endsWith('-variant-base'))
  const questionCreationSupported =
    questionsEnabled &&
    ((environment?.kind === EnvironmentTypes.Language && !environment?.allowMultipleFiles) ||
      environment?.kind === EnvironmentTypes.Project)

  const openLangInfo = useCallback(() => {
    if (environment?.spreadsheet) {
      setAnchorEl(null)
      setLanguage({
        display: 'Google Sheets',
        name: 'gsheets',
        category: 'spreadsheets',
      } as LanguageConfiguration)
      trackEvent('Language Info Clicked', {
        language: 'gsheets',
        from: 'context-menu',
      })
    } else if (environment?.language) {
      setAnchorEl(null)
      setLanguage(window.CoderPad.LANGUAGES[environment.language])
      trackEvent('Language Info Clicked', {
        language: environment.language,
        from: 'context-menu',
      })
    } else if (environment?.projectTemplateSlug?.startsWith('jupyter')) {
      const lang = {
        name: environment.projectTemplateSlug,
        display: environment.nameDisplayable,
        type: 'project',
      } as LanguageConfiguration
      setAnchorEl(null)
      setLanguage(lang)
      trackEvent('Language Info Clicked', {
        language: environment.projectTemplateSlug,
        from: 'context-menu',
      })
    }
  }, [environment, setAnchorEl, setLanguage])

  const handleToggleEnvironmentSelectorMenu = useCallback(() => {
    setAnchorEl(null)
    openMenuAndSetTab(EnvironmentSelectorMenuTabs.Languages)
  }, [openMenuAndSetTab, setAnchorEl])

  const requestEnvironmentReset = useCallback(
    (env: EnvironmentSummary) => {
      setEnvToReset(env)
      setAnchorEl(null)
    },
    [setEnvToReset, setAnchorEl]
  )

  const renderLanguageRow = useCallback(
    (props: ListChildComponentProps) => {
      const { style, index, data } = props
      const lang = data[index]

      return (
        <ListItem style={style} button onClick={() => changeLanguage(lang.name)} key={lang.name}>
          <StyledLanguageIcon language={lang.name} />
          <Box ml={1} component="span">
            {lang.display}
          </Box>
        </ListItem>
      )
    },
    [changeLanguage]
  )

  const filterLangs = useCallback(
    (val: string) => {
      setFilteredLangs(availableLangs.filter((lang) => lang.name.startsWith(val)))
    },
    [setFilteredLangs, availableLangs]
  )

  const mouseLeaveTimeout = useRef<NodeJS.Timeout | null>(null)
  const handleMouseEnter = useCallback(() => {
    if (mouseLeaveTimeout.current) {
      clearTimeout(mouseLeaveTimeout.current)
      mouseLeaveTimeout.current = null
    }
    setIsMouseOnMenu(true)
  }, [setIsMouseOnMenu])

  const handleMouseLeave = useCallback(() => {
    mouseLeaveTimeout.current = setTimeout(() => {
      setIsMouseOnMenu(false)
    }, 50)
  }, [setIsMouseOnMenu])
  const showMenu = useCallback(() => {
    if (isPlayback) {
      if (question == null) {
        return false
      }
    }
    return (isMouseOnTab || isMouseOnMenu) && !!anchorEl
  }, [isPlayback, question, isMouseOnTab, isMouseOnMenu, anchorEl])

  const livePadMenuOptions = useMemo(() => {
    const options = []
    if (environment.isQuestionWithVariants) {
      // don't include the ability to switch Language, that is handled by the Question Variant select in the EditorHeader
    } else if (availableLangs.length) {
      options.push(
        <MenuItem
          onClick={() => setLangSelectionOpen(!langSelectionOpen)}
          key="env-settings-language-item"
        >
          <CustomIcon icon={Icons.Languages} sx={{ mr: 1 }} />
          <Box display="flex" justifyContent="space-between" width="100%">
            Language
            <KeyboardArrowDownIcon
              className={clsx(styles.languageMenuIndicator, { open: langSelectionOpen })}
            />
          </Box>
        </MenuItem>
      )
      options.push(
        <Collapse
          in={langSelectionOpen}
          sx={(theme) => ({
            backgroundColor: theme.palette.tabNavHoverMenu.collapseMenu.backgroundColor,
          })}
          key="env-settings-language-menu"
        >
          <SearchInput
            onValueChange={filterLangs}
            sx={(theme) => ({ marginY: theme.spacing(1), marginX: theme.spacing(2) })}
            InputProps={{
              sx: { backgroundColor: 'transparent' },
              onKeyDown: (e) => e.stopPropagation(),
            }}
          />
          <ScrollView maxHeight="322px" overflow="auto">
            <FixedSizeList
              height={322}
              width="100%"
              itemCount={filteredLangs.length}
              itemSize={36}
              itemData={filteredLangs}
            >
              {renderLanguageRow}
            </FixedSizeList>
          </ScrollView>
        </Collapse>
      )
    } else {
      options.push(
        <MenuItem onClick={handleToggleEnvironmentSelectorMenu} key="env-settings-open-languages">
          <CustomIcon icon={Icons.Switch} sx={{ mr: 1 }} />
          <Box display="flex" justifyContent="space-between" width="100%">
            Switch Language
          </Box>
        </MenuItem>
      )
    }
    if (snippet) {
      options.push(
        <MenuItem onClick={() => setSnippetInfoDetail(snippet)} key="env-settings-snippet-info">
          <InfoOutlined sx={{ mr: 1, width: 16, height: 16 }} />
          Snippet Info
        </MenuItem>
      )
    }
    if (hasLangInfo) {
      options.push(
        <MenuItem onClick={openLangInfo} key="env-settings-lang-info">
          <InfoOutlined sx={{ mr: 1, width: 16, height: 16 }} />
          Language Info
        </MenuItem>
      )
    }
    if (isOwner && environment && !sandboxEnvironmentPreviewSlug) {
      options.push(
        <MenuItem onClick={() => requestEnvironmentDelete(environment)} key="env-settings-delete">
          <CustomIcon icon={Icons.Delete} sx={{ mr: 1 }} />
          Delete Tab
        </MenuItem>
      )
    }
    // "resetting" not supported in spreadsheet environments or "base" question variant envs
    if (
      (isOwner || takeHome) &&
      environment.spreadsheet == null &&
      !environment.slug.endsWith('-variant-base') &&
      environment
    ) {
      options.push(
        <MenuItem onClick={() => requestEnvironmentReset(environment)} key="env-settings-reset">
          <CustomIcon icon={Icons.Reset} sx={{ mr: 1 }} />
          Reset Tab
        </MenuItem>
      )
    }
    if (questionCreationSupported) {
      options.push(
        <MenuItem
          onClick={() => setEnvToCreateQuestionFrom(environment)}
          key="env-settings-create-question"
          sx={{
            // TODO: use theme colors from shared component lib once theme consolidation/extension is finished.
            color: (theme) => (theme.palette.mode === 'dark' ? '#F4C536' : '#1142AD'),
            '& svg': {
              '& path': {
                stroke: (theme) => (theme.palette.mode === 'dark' ? '#F4C536' : '#1142AD'),
              },
            },
          }}
        >
          <CustomIcon
            icon={Icons.Questions}
            sx={{
              mr: 1,
            }}
          />
          Save code as draft question
        </MenuItem>
      )
    }
    if (!takeHome && availablePackages.length) {
      options.push(
        <MenuItem
          onClick={() => setPackageSelectionOpen(!packageSelectionOpen)}
          key="env-settings-database-adapters"
        >
          <CustomIcon icon={Icons.Database} sx={{ mr: 1 }} />
          <Box display="flex" justifyContent="space-between" width="100%">
            {/* We are going to assume that the only "packages" are DB adapters, so call them that. */}
            Database adapters
            <KeyboardArrowDownIcon
              className={clsx(styles.languageMenuIndicator, { open: packageSelectionOpen })}
            />
          </Box>
        </MenuItem>
      )
    }
    options.push(
      <Collapse
        in={packageSelectionOpen}
        sx={(theme) => ({
          backgroundColor: theme.palette.tabNavHoverMenu.collapseMenu.backgroundColor,
        })}
        key="env-settings-database-adapters-menu"
      >
        <ScrollView maxHeight="300px" overflow="auto">
          <List>
            {availablePackages.map((pack) => {
              return (
                <ListItem
                  button
                  onClick={() => setCustomDatabaseLanguage(pack.database, pack.example)}
                  key={pack.name}
                >
                  <StyledLanguageIcon language={pack.database} />
                  <Box ml={1} component="span">
                    {pack.display}
                  </Box>
                </ListItem>
              )
            })}
          </List>
        </ScrollView>
      </Collapse>
    )
    return options
  }, [
    availableLangs.length,
    langSelectionOpen,
    filterLangs,
    filteredLangs,
    renderLanguageRow,
    hasLangInfo,
    isOwner,
    environment,
    sandboxEnvironmentPreviewSlug,
    snippet,
    takeHome,
    questionCreationSupported,
    availablePackages,
    packageSelectionOpen,
    styles.languageMenuIndicator,
    handleToggleEnvironmentSelectorMenu,
    openLangInfo,
    requestEnvironmentDelete,
    requestEnvironmentReset,
    setCustomDatabaseLanguage,
  ])

  return (
    <>
      <Menu
        id="environment-selector-language-menu"
        anchorEl={anchorEl}
        open={showMenu()}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        data-testid="envselector-envsettingsmenu-menu"
        classes={{ list: styles.menuList, root: styles.menuRoot }}
        marginThreshold={0}
        PaperProps={{
          sx: {
            backgroundColor: (theme) => theme.palette.editor?.sidebar.active,
            borderRadius: '4px',
          },
          elevation: 0,
        }}
        MenuListProps={{
          onMouseEnter: handleMouseEnter,
          onMouseLeave: handleMouseLeave,
          sx: (theme) => ({
            paddingX: 0,
            paddingTop: isOwner && question != null ? 0 : theme.spacing(1.5),
            paddingBottom: isPlayback ? 0 : theme.spacing(1.5),
            pointerEvents: 'auto',
          }),
          autoFocusItem: false,
        }}
        sx={{
          [`&.${popoverClasses.root}`]: {
            pointerEvents: 'none',
          },
        }}
      >
        {(isOwner || isPlayback) && question != null && (
          <MenuItem
            sx={(theme) => ({
              backgroundColor: theme.palette.tabNavHoverMenu.questionTitle.backgroundColor,
              paddingY: theme.spacing(0.5),
              cursor: isPlayback ? 'default' : 'pointer',
            })}
          >
            <Box alignItems="flex-start" alignContent="flex-start">
              <QuestionTitle
                sx={(theme) => ({
                  marginY: theme.spacing(0.5),
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  wordBreak: 'break-all',
                  whiteSpace: 'normal',
                })}
                title={question.title || 'Question'}
              >
                {question.title || 'Question'}
              </QuestionTitle>
              <QuestionTitleSubtext
                color={(theme) => theme.palette.tabNavHoverMenu.questionSubtext.color}
                sx={(theme) => ({ paddingY: theme.spacing(0.5) })}
              >
                Title not visible to candidate
              </QuestionTitleSubtext>
            </Box>
          </MenuItem>
        )}
        {!isPlayback ? livePadMenuOptions : null}
      </Menu>
      <ResetEnvironmentDialog environment={envToReset} onRequestClose={() => setEnvToReset(null)} />
      <CreateQuestionDialog
        environment={envToCreateQuestionFrom}
        onRequestClose={() => setEnvToCreateQuestionFrom(null)}
        hasDrawingBoardId={!!drawingBoardId}
      />
      <SnippetInfoDialog
        snippet={snippetInfoDetail}
        onRequestClose={() => setSnippetInfoDetail(null)}
      />
    </>
  )
}
