import {
  LogoOutlined as InterviewDetailsIcon,
  Users as AttendanceIcon,
} from '@codinpad/shared-components/components/icons/index.js'
import { Box, Stack } from '@mui/material'
import { PadSummary } from 'graphql/types'
import React, { FC, useMemo } from 'react'
import { pluralize } from 'utils/i18n'

import { CodeAndQuestions } from './components/CodeAndQuestions'
import { OtherInfo } from './components/OtherInfo'
import {
  SectionTitle,
  SummaryTable,
  SummaryTableCell,
  SummaryTableCellEmphasized,
  SummaryTableContainer,
  SummaryTableRow,
} from './components/StyledComponents'
import { SuspiciousBehavior } from './components/SuspiciousBehavior'
import { displayDate, displayDuration, displayDurationBetweenTimes } from './utils'

interface LivePadSummaryProps {
  padSummary: PadSummary
}

export const LivePadSummary: FC<LivePadSummaryProps> = ({ padSummary }) => {
  const interviewers = useMemo(() => {
    if (padSummary == null) {
      return []
    }
    return padSummary.participants.filter((p) => p.isInterviewer)
  }, [padSummary])

  const candidates = useMemo(() => {
    if (padSummary == null) {
      return []
    }
    return padSummary.participants.filter((p) => !p.isInterviewer)
  }, [padSummary])

  const waitingRoomUsed = useMemo(() => {
    return candidates.map((c) => c.waitingRoomTime ?? 0).reduce((sum, item) => sum + item, 0) > 0
  }, [candidates])

  const transcriptionParticipantCount = useMemo(() => {
    return padSummary.participants.filter((p) => p.talkTime != null && p.talkTime > 0).length
  }, [padSummary.participants])

  if (padSummary == null) {
    return null
  }

  return (
    <SummaryTableContainer>
      <SummaryTable size="small">
        <SummaryTableRow>
          <SectionTitle>
            <Stack direction="row" spacing={1}>
              <Box pt={0.3}>
                <InterviewDetailsIcon sx={{ width: 14, height: 14 }} />
              </Box>
              <Box>Interview Details</Box>
            </Stack>
          </SectionTitle>
        </SummaryTableRow>
        <SummaryTableRow>
          <SummaryTableCellEmphasized>Interview started:</SummaryTableCellEmphasized>
          <SummaryTableCell>{displayDate(padSummary.interviewStartedAt)}</SummaryTableCell>
        </SummaryTableRow>
        <SummaryTableRow>
          <SummaryTableCellEmphasized>Interview ended:</SummaryTableCellEmphasized>
          <SummaryTableCell>{displayDate(padSummary.interviewEndedAt)}</SummaryTableCell>
        </SummaryTableRow>
        <SummaryTableRow>
          <SummaryTableCellEmphasized>Interview title:</SummaryTableCellEmphasized>
          <SummaryTableCell>{padSummary.padTitle}</SummaryTableCell>
        </SummaryTableRow>
        <SummaryTableRow>
          <SummaryTableCellEmphasized>Duration:</SummaryTableCellEmphasized>
          <SummaryTableCell>
            {displayDurationBetweenTimes(
              padSummary.interviewStartedAt,
              padSummary.interviewEndedAt
            )}
          </SummaryTableCell>
        </SummaryTableRow>

        <SummaryTableRow>
          <SectionTitle>
            <Stack direction="row" spacing={1}>
              <Box pt={0.3}>
                <AttendanceIcon sx={{ width: 14, height: 14 }} />
              </Box>
              <Box>Attendance</Box>
            </Stack>
          </SectionTitle>
        </SummaryTableRow>
        <SummaryTableRow>
          <SummaryTableCellEmphasized>Waiting room:</SummaryTableCellEmphasized>
          <SummaryTableCell>
            {!waitingRoomUsed && 'Not used'}
            {waitingRoomUsed &&
              candidates.map((p, i) => (
                <Box key={i}>
                  <b>{p.name}</b> spent {displayDuration(p.waitingRoomTime)} in the waiting room
                </Box>
              ))}
          </SummaryTableCell>
        </SummaryTableRow>
        <SummaryTableRow>
          <SummaryTableCellEmphasized>Attendees:</SummaryTableCellEmphasized>
          <SummaryTableCell>
            <Box>
              <b>{pluralize('Interviewer', interviewers.length)}:</b>
            </Box>
            {interviewers.map((p, i) => (
              <Box key={i}>
                <b>{p.name}</b> joined {displayDate(p.joinedAt)}
              </Box>
            ))}
            <br />
            <Box>
              <b>{pluralize('Candidate', candidates.length)}:</b>
            </Box>
            {candidates.map((p, i) => (
              <Box key={i}>
                <b>{p.name}</b> joined {displayDate(p.joinedAt)}
              </Box>
            ))}
          </SummaryTableCell>
        </SummaryTableRow>
        <SummaryTableRow>
          <SummaryTableCellEmphasized>Talk time:</SummaryTableCellEmphasized>
          <SummaryTableCell>
            <Box>
              {transcriptionParticipantCount}{' '}
              {pluralize('transcription participant', transcriptionParticipantCount)}
            </Box>
            {padSummary.participants
              .filter((p) => p.talkTime > 0)
              .map((p, i) => (
                <Box key={i}>
                  <b>{p.name}</b> - {displayDuration(p.talkTime)}
                </Box>
              ))}
          </SummaryTableCell>
        </SummaryTableRow>

        <CodeAndQuestions padSummary={padSummary} />
        <SuspiciousBehavior padSummary={padSummary} candidates={candidates} />
        <OtherInfo padSummary={padSummary} />
      </SummaryTable>
    </SummaryTableContainer>
  )
}
