import { Dialog, DialogContent } from '@mui/material'
import firebase from 'firebase/compat/app'
import { usePadConfigValue } from 'packs/dashboard/components/PadContext/PadContext'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ErrorBoundary } from '../../dashboard/components/ErrorBoundary/ErrorBoundary'
import { GenericErrorView } from '../../dashboard/components/GenericErrorView/GenericErrorView'
import {
  selectAudioMuted,
  selectCallMaximized,
  selectCallQuality,
  selectCallStatus,
  selectLocalUsername,
  selectMyUserId,
  selectTwilioUsers,
  selectUserInfo,
  selectVideoMuted,
} from '../selectors'
import SyncHandle from '../sync_handle'
import {
  TranscriberStopReason,
  useTranscriberContext,
} from '../Transcriber/TranscriberContext/TranscriberContext'
import CallData from './call_data'
import { CallErrorScreen } from './CallErrorScreen'
import { CallInviteScreen } from './CallInvite'
import { PendingScreen } from './PendingScreen'
import { TranscriptConsent } from './TranscriptConsent'
import TwilioMaximizedCall from './twilio/maximized_call'
import TwilioMinimizedCall from './twilio/minimized_call'
import { DynamicCall } from './zoom/DynamicCall'
import { ZoomRoomRelayProvider } from './zoom/ZoomRoomRelay'

type CallStatus = 'pending' | 'invited' | 'in_call' | 'error' | 'transcription_consent' | 'no_call'

export interface TwilioCaller {
  audio: string
  video: string
  name: string
  id: string
  self: boolean
}

interface TwilioUser {
  audioTrackId?: string
  participantId: string
  videoTrackId?: string
}

interface TwilioUserMap {
  [userId: string]: TwilioUser
}

const _CallRoot: FC = () => {
  const dispatch = useDispatch()
  const callStatus: CallStatus = useSelector(selectCallStatus)
  const twilioUsers: TwilioUserMap = useSelector(selectTwilioUsers)
  const selfUserId = useSelector(selectMyUserId)
  const selfName = useSelector(selectLocalUsername)
  const firebaseUserInfo = useSelector(selectUserInfo)
  const audioMuted = useSelector(selectAudioMuted)
  const videoMuted = useSelector(selectVideoMuted)
  const maximized = useSelector(selectCallMaximized)
  const networkQualityLevel = useSelector(selectCallQuality)
  const videoServiceProvider = usePadConfigValue('videoServiceProvider')

  const [declinedCall, setDeclinedCall] = useState(false)

  useEffect(() => {
    let handler: (snap: firebase.database.DataSnapshot) => void
    if (callStatus === 'no_call' && !declinedCall && videoServiceProvider === 'zoom') {
      handler = SyncHandle().watch('zoomRoomId', (zoomRoomId: string) => {
        if (zoomRoomId) {
          dispatch({
            type: 'invited_to_call',
            _analytics: {
              name: 'Call Setup',
              params: {
                from_invite: true,
                from_reconnect: false,
              },
            },
          })
        }
      })
    }

    return () => {
      if (handler) {
        SyncHandle().off('zoomRoomId', handler)
      }
    }
  }, [dispatch, callStatus, declinedCall, videoServiceProvider])

  const {
    closedCaptionsEnabled,
    muteTranscription,
    stopTranscription,
    toggleClosedCaptions,
  } = useTranscriberContext()

  const [callers, setCallers] = useState<TwilioCaller[]>([])

  const MaximizedCall = useMemo(() => {
    return videoServiceProvider === 'twilio' ? TwilioMaximizedCall : DynamicCall
  }, [videoServiceProvider])

  const MinimizedCall = useMemo(() => {
    return videoServiceProvider === 'twilio' ? TwilioMinimizedCall : DynamicCall
  }, [videoServiceProvider])

  useEffect(() => {
    if (videoServiceProvider !== 'twilio') {
      return
    }

    const callers: TwilioCaller[] = []
    if (callStatus === 'in_call') {
      callers.push({
        audio: CallData.localAudioTrack,
        video: CallData.localVideoTrack,
        name: selfName,
        id: selfUserId,
        self: true,
      })
      const room = CallData.room
      for (const [userId, twilioInfo] of Object.entries(twilioUsers)) {
        // Don't push a duplicate of own tracks
        if (userId === selfUserId) continue

        if (twilioInfo.participantId) {
          const participant = room.participants.get(twilioInfo.participantId)
          callers.push({
            audio:
              twilioInfo.audioTrackId && participant.audioTracks.get(twilioInfo.audioTrackId).track,
            video:
              twilioInfo.videoTrackId && participant.videoTracks.get(twilioInfo.videoTrackId).track,
            // Twilio and Firebase each have presence info, cached client-side in the call
            // and user-state reducers, respectively. Here, we join the two in order to get
            // a remote caller's name, which only Firebase knows. In the edge case where someone
            // is connected to the call but has lost their Firebase connection, we may be
            // unable to display a name.
            //
            // TODO: Cache the last known name of someone who has disconnected from Firebase
            // so their name won't disappear from the UI in this case.
            name: (firebaseUserInfo[userId] && firebaseUserInfo[userId].name) || '',
            id: userId,
            self: false,
          })
        }
      }
      setCallers(callers)
    }
  }, [callStatus, firebaseUserInfo, selfName, selfUserId, twilioUsers, videoServiceProvider])

  let callComponent = null
  switch (callStatus) {
    case 'pending':
      callComponent = <PendingScreen />
      break
    case 'transcription_consent':
      callComponent = <TranscriptConsent />
      break
    case 'invited':
      callComponent = (
        <CallInviteScreen callParticipants={callers} onDecline={() => setDeclinedCall(true)} />
      )
      break
    case 'in_call': {
      const CallComponent = maximized ? MaximizedCall : MinimizedCall
      // TODO fix excessive props
      callComponent = (
        <ZoomRoomRelayProvider>
          <CallComponent
            ccEnabled={closedCaptionsEnabled}
            toggleCC={toggleClosedCaptions}
            callers={callers}
            endCall={() => {
              dispatch({ type: 'call_ended', _analytics: { name: 'Call Left' } })
              stopTranscription(TranscriberStopReason.CallEnded)
            }}
            minimize={() =>
              dispatch({ type: 'call_minimized', _analytics: { name: 'Call Minimized' } })
            }
            maximize={() =>
              dispatch({ type: 'call_maximized', _analytics: { name: 'Call Maximized' } })
            }
            unmuteVideo={() => dispatch({ type: 'mute_clicked', kind: 'video', enabled: true })}
            unmuteAudio={() => {
              dispatch({ type: 'mute_clicked', kind: 'audio', enabled: true })
              muteTranscription(false)
            }}
            muteVideo={() => dispatch({ type: 'mute_clicked', kind: 'video', enabled: false })}
            muteAudio={() => {
              dispatch({ type: 'mute_clicked', kind: 'audio', enabled: false })
              muteTranscription(true)
            }}
            videoMuted={videoMuted}
            audioMuted={audioMuted}
            // @ts-ignore number is sufficient.
            networkQualityLevel={networkQualityLevel}
          />
        </ZoomRoomRelayProvider>
      )
      break
    }
    case 'error':
      callComponent = <CallErrorScreen />
      break
    default:
      callComponent = null
  }

  return <>{callComponent}</>
}

function ErrorFallback({ e }: { e?: Error }) {
  const [isOpen, setIsOpen] = useState(true)
  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
      <DialogContent>
        <GenericErrorView error={e} message="An error occurred while starting the call." />
      </DialogContent>
    </Dialog>
  )
}

function CallRoot() {
  return (
    <ErrorBoundary fallback={(e) => <ErrorFallback e={e} />}>
      <_CallRoot />
    </ErrorBoundary>
  )
}

export default CallRoot
