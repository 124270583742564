import { Info as OtherInfoIcon } from '@codinpad/shared-components/components/icons/index.js'
import { Box, Stack } from '@mui/material'
import { PadSummary } from 'graphql/types'
import React, { FC } from 'react'

import {
  SectionTitle,
  SummaryTableCell,
  SummaryTableCellEmphasized,
  SummaryTableRow,
} from './StyledComponents'

export const OtherInfo: FC<{ padSummary: PadSummary }> = ({ padSummary }) => {
  return (
    <>
      <SummaryTableRow>
        <SectionTitle>
          <Stack direction="row" spacing={1}>
            <Box pt={0.3}>
              <OtherInfoIcon sx={{ width: 14, height: 14 }} />
            </Box>
            <Box>Other Information</Box>
          </Stack>
        </SectionTitle>
      </SummaryTableRow>
      <SummaryTableRow>
        <SummaryTableCellEmphasized>Interviewer notes:</SummaryTableCellEmphasized>
        <SummaryTableCell>{padSummary.interviewerNotesEdited ? 'Yes' : 'No'}</SummaryTableCell>
      </SummaryTableRow>
      <SummaryTableRow>
        <SummaryTableCellEmphasized>AI assist used:</SummaryTableCellEmphasized>
        <SummaryTableCell>{padSummary.aiAssistUsed ? 'Yes' : 'No'}</SummaryTableCell>
      </SummaryTableRow>
      <SummaryTableRow>
        <SummaryTableCellEmphasized>Transcription:</SummaryTableCellEmphasized>
        <SummaryTableCell>{padSummary.transcriptCaptured ? 'Yes' : 'No'}</SummaryTableCell>
      </SummaryTableRow>
      <SummaryTableRow>
        <SummaryTableCellEmphasized>Drawing:</SummaryTableCellEmphasized>
        <SummaryTableCell>{padSummary.drawingUsed ? 'Yes' : 'No'}</SummaryTableCell>
      </SummaryTableRow>
    </>
  )
}
