import { Box, CircularProgress } from '@mui/material'
import React from 'react'

export const SummaryLoading: React.FC = () => {
  return (
    <Box
      mt={10}
      textAlign="center"
      sx={(theme) => ({ color: theme.palette.programOutput.tabs.text, overflow: 'hidden' })}
    >
      <CircularProgress sx={(theme) => ({ color: theme.palette.programOutput.tabs.text })} />
      <Box mt={4}>Generating Interview Summary...</Box>
    </Box>
  )
}
