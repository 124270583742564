import { MessageRelayMessage, MessageRelayType } from 'packs/zoom_room/types'
import React, { useCallback, useMemo, useRef } from 'react'

interface ZoomRoomRelayContext {
  /**
   * The react ref for the iframe element
   */
  iframe: React.RefObject<HTMLIFrameElement>
  sendMessage: (message: any) => void
  onMessage: (type: MessageRelayType, callback: (message: any) => void) => () => void
}

const defaultContext: ZoomRoomRelayContext = {
  iframe: { current: null },
  sendMessage: () => {},
  onMessage: () => () => {},
}

export const ZoomRoomRelayContext = React.createContext<ZoomRoomRelayContext>(defaultContext)

interface ZoomRoomRelayProviderProps {
  children: React.ReactNode
}

export const ZoomRoomRelayProvider: React.FC<ZoomRoomRelayProviderProps> = ({ children }) => {
  const iframe = useRef<HTMLIFrameElement>(null)

  const sendMessage = useCallback((message: MessageRelayMessage) => {
    if (iframe.current) {
      iframe.current.contentWindow?.postMessage(message, '*')
    }
  }, [])

  const onMessage = useCallback(
    (type: MessageRelayType, callback: (message: MessageEvent) => void) => {
      const listener = (event: MessageEvent) => {
        if (event.data.type === type) {
          callback(event)
        }
      }

      window.addEventListener('message', listener)

      return () => {
        window.removeEventListener('message', listener)
      }
    },
    []
  )

  const value = useMemo(() => ({ iframe, sendMessage, onMessage }), [
    iframe,
    sendMessage,
    onMessage,
  ])

  return <ZoomRoomRelayContext.Provider value={value}>{children}</ZoomRoomRelayContext.Provider>
}

export const useZoomRoomRelay = (): ZoomRoomRelayContext => {
  const context = React.useContext(ZoomRoomRelayContext)
  if (!context) {
    throw new Error('useZoomRoomRelay must be used within a `ZoomRoomRelayProvider`')
  }
  return context
}
